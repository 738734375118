<template>
  <body>
    <!-- preloader -->
    <Loader> </Loader>
    <!-- preloader -->
    <div class="frame">
      <div class="site-wrapper overflow-hidden">

        <!-- Header Section -->
        <!-- <Front-Header> </Front-Header> -->

        <div class="patners-listing-details-page">
          <section class="comapny-details">
            <div class="company-bg">
              <img :src="partner.company_hero_image" />
              <img class="company_logo" :src="partner.company_image" />
            </div>
            <div class="container">

              <div class="company-info">
                <h1>{{ company_name }}</h1>
                <div id="social">
                  <a target="_blank" :href="partner.facebook" class="facebookBtn smGlobalBtn"></a>
                  <!-- <a target="_blank" :href="partner.twiter" class="linkedinBtn smGlobalBtn"></a> -->
                  <a target="_blank" :href="partner.instagram" class="rssBtn smGlobalBtn"></a>
                  <a target="_blank" :href="partner.twiter" class="tumblrBtn smGlobalBtn"></a>
                </div>


              </div>
            </div>
          </section>

          <section class="about-company">
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <img class="about-img" :src="partner.image" />
                </div>
                <div class="col-md-8">
                  <div class="about-details">
                    <h2>ABOUT</h2>
                    <p>
                      {{ partner.about }}
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </section>

          <section class="contact-us">
            <div class="container">
              <h3 class="section_heading">Get In Touch</h3>
              <div class="box--grid__items">
                <div class="box--grid__item">
                  <h3 class="box--grid__title">Contact Us</h3>
                  <p><span>Phone:</span>{{ partner.phone }}</p>
                  <p><span>Email:</span>{{ partner.email }}</p>
                </div>

                <div class="box--grid__item">
                  <h3 class="box--grid__title">Address</h3>
                  <p>{{ partner.address }}</p>
                </div>

                <div class="box--grid__item">
                  <h3 class="box--grid__title">Opening Hours</h3>
                  <table class="opening-hours table table-hover table-condensed borderless">
                    <thead>
                      <tr>
                        <!-- <th colspan="2" class="table-headline"><span style="font-size: 18px;">Opening hours</span></th> -->
                      </tr>
                    </thead>
                    <!-- edit your hours here -->
                    <tbody>
                      <tr>
                        <th>Monday</th>
                        <td>
                          {{ partner.monday }}
                        </td>
                      </tr>
                      <tr>
                        <th>Tuesday</th>
                        <td>
                          {{ partner.tuesday }}
                        </td>
                      </tr>
                      <tr>
                        <th>Wednesday</th>
                        <td>
                          {{ partner.wednesday }}
                        </td>
                      </tr>
                      <tr>
                        <th>Thursday</th>
                        <td>
                          {{ partner.thursday }}
                        </td>
                      </tr>
                      <tr>
                        <th>Friday</th>
                        <td>
                          {{ partner.friday }}
                        </td>
                      </tr>
                      <tr>
                        <th>Saturday</th>
                        <td>
                          {{ partner.saturday }}
                        </td>
                      </tr>
                      <tr>
                        <th>Sunday</th>
                        <td>
                          {{ partner.sunday }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

            </div>
          </section>

          <!-- Footer Section -->
          <Front-Footer> </Front-Footer>


        </div>
      </div>
    </div>
  </body>
</template>
<script>
import Loader from './Loader';
import Header from './Front-Header';
import Footer from './Front-Business-Footer';
import { db } from "@/main";
export default {
  components: {
    'Front-Header': Header,
    'Front-Footer': Footer,
    'Loader': Loader,
  },
  data() {
    return {
      about: '',
      partner: '',
      address: '',
      companyheroimage: '',
      companylogo: '',
      email: '',
      facebook: '',
      instagram: '',
      ohours: '',
      phone: '',
      secondsectionimage: '',
      slugurl: '',
      thirdsectionimage: '',
      title: '',
      twiter: '',
    }

  },
  methods: {

    readEmployees() {

      db.collection("partner")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // db.collection('companies').doc(doc.data().title)
            //   .get().then(snapshot => {
                if (doc.data().slugurl == this.$route.params.slug) {
                  this.partner = doc.data();
                  this.company_name = doc.data().title;

                  console.log("partner => ", this.partner);

                  // var currentUrl = window.location.pathname;
                  // this.url = currentUrl.replace('/'+this.$route.params.slug,'/'+this.company_name.replace(/\s+/g, '-').toLowerCase());
                  // this.$router.push(this.url);
                }
              });
          // });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  },
  mounted() {
    this.readEmployees();
    // var currentUrl = window.location.pathname;
    // var id = this.$route.params.slug;
    // alert(this.company_name)
    // var url = currentUrl.replace('/'+id,'/arbaz');
    // console.log(`Current URL => ${url}`);
    // setTimeout(() => {
    //   this.$router.push(url);
    // }, 5000);
  },

};
</script>
<style scoped>
.patners-listing-details-page .company-bg {
  height: 80vh;
  position: relative;
}

.patners-listing-details-page .company-bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}

section.comapny-details {
  position: relative;
}

.patners-listing-details-page .company-bg>img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.company_logo {
  max-width: 150px;
  min-width: 150px;
  max-height: 150px;
  min-height: 150px;
  border-radius: 50%;
  border: 10px solid #fff;
  position: absolute;
  left: 20%;
  top: 10%;
}

/* socail media */
@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');

#social {
  margin: 20px 10px;
  text-align: center;
}

.smGlobalBtn {
  /* global button class */
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: 2px solid #ddd;
  /* add border to the buttons */
  padding: 0px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: normal;
  line-height: 2em;
  border-radius: 27px;
  -moz-border-radius: 27px;
  -webkit-border-radius: 27px;
  margin-left: 10px;
}

/* facebook button class*/
.facebookBtn {
  background: #4060A5;
}

.facebookBtn:before {
  /* use :before to add the relevant icons */
  font-family: "FontAwesome";
  content: "\f09a";
  /* add facebook icon */
}

.facebookBtn:hover {
  color: #4060A5;
  background: #fff;
  border-color: #4060A5;
  /* change the border color on mouse hover */
}

/* twitter button class*/
.twitterBtn {
  background: #00ABE3;
}

.twitterBtn:before {
  font-family: "FontAwesome";
  content: "\f099";
  /* add twitter icon */

}

.twitterBtn:hover {
  color: #00ABE3;
  background: #fff;
  border-color: #00ABE3;
}

/* google plus button class*/
.googleplusBtn {
  background: #e64522;
}

.googleplusBtn:before {
  font-family: "FontAwesome";
  content: "\f0d5";
  /* add googleplus icon */
}

.googleplusBtn:hover {
  color: #e64522;
  background: #fff;
  border-color: #e64522;
}

/* linkedin button class*/
.linkedinBtn {
  background: #0094BC;
}

.linkedinBtn:before {
  font-family: "FontAwesome";
  content: "\f0e1";
  /* add linkedin icon */
}

.linkedinBtn:hover {
  color: #0094BC;
  background: #fff;
  border-color: #0094BC;
}

/* pinterest button class*/
.pinterestBtn {
  background: #cb2027;
}

.pinterestBtn:before {
  font-family: "FontAwesome";
  content: "\f0d2";
  /* add pinterest icon */
}

.pinterestBtn:hover {
  color: #cb2027;
  background: #fff;
  border-color: #cb2027;
}

/* tumblr button class*/
.tumblrBtn {
  background: #1DA1F2;
}

.tumblrBtn:before {
  font-family: "FontAwesome";
  content: "\f099";
  /* add tumblr icon */
}

.tumblrBtn:hover {
  color: #3a5876;
  background: #fff;
  border-color: #3a5876;
}

/* rss button class*/
.rssBtn {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.rssBtn:before {
  font-family: "FontAwesome";
  content: "\f16d";
  /* add rss icon */
}

.rssBtn:hover {
  color: #cc2366;
  background: #fff;
  border-color: #cc2366;
}

.patners-listing-details-page .company-info {
  display: flex;
  align-items: flex-start;
  position: absolute;
  bottom: 80px;
  justify-content: space-between;
  width: 100%;
  left: 0;
}

.patners-listing-details-page .container {
  position: relative;
}

.patners-listing-details-page .company-info h1 {
  color: #fff;
  font-size: 55px;
}

section.about-company {
  padding: 100px 0;
}

section.about-company .about-img {
  min-height: 300px;
  width: 100%;
  display: block;
  max-height: 300px;
  object-fit: cover;
}

section.about-company .about-details h2 {
  font-size: 40px;
  margin-bottom: 0px;
}

section.about-company .about-details p {
  font-size: 16px;
  color: #585858;
}

.box--grid__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.box--grid__item {
  flex: 0 0 32.33%;
  max-width: 32.33%;
  padding: 50px 30px;
  margin-bottom: 35px;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 250ms;
  cursor: pointer;
}

.box--grid__item p {
  margin-bottom: 0;
}

section.contact-us {
  padding: 170px 0;
  background-image: url(https://images.unsplash.com/photo-1487611459768-bd414656ea10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

section.contact-us .container {
  position: relative;
}

section.contact-us:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: #000;
  opacity: 0.4;
}

section.contact-us .section_heading {
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  font-size: 50px;
}

.box--grid__item:hover .box--grid__title,
.box--grid__item:hover p {
  color: #fff;
}

.box--grid__item:hover {
  color: #fff;
  background-color: #03e1bc;
  transition: background-color 250ms;
}

footer {
  min-height: 100px;
  display: flex;
  align-items: center;
}

footer .wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

footer .wrapper a img {
  max-width: 100px;
}

footer .wrapper p {
  margin-bottom: 0;
  margin-right: 20px;
}

@media (max-width:1440px) {
  .company_logo {
    max-width: 130px;
    min-width: 130px;
    max-height: 130px;
    min-height: 130px;
    left: 5%;
  }

  section.contact-us {
    padding: 100px 0;
  }
}

@media (max-width:1280px) {
  .company_logo {
    left: 1%;
  }

  .patners-listing-details-page .company-info h1 {
    font-size: 45px;
  }
}

@media (max-width:1024px) {
  footer .wrapper {
    padding: 0 2%;
  }
}

@media (max-width:768px) {
  .patners-listing-details-page .company-info {
    padding: 0 2%;
  }

  .company_logo {
    max-width: 90px;
    min-width: 90px;
    max-height: 90px;
    min-height: 90px;
    left: 2%;
  }

  .patners-listing-details-page .company-info h1 {
    font-size: 35px;
  }

  section.about-company .about-details p {
    font-size: 14px;
  }

  .box--grid__item h3.box--grid__title {
    font-size: 20px;
  }

  .box--grid__item p {
    font-size: 14px;
    max-width: 90%;
    word-break: break-all;
  }
}

@media (max-width:767px) {
  .patners-listing-details-page .company-info {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .patners-listing-details-page .company-bg {
    height: 60vh;
  }

  .patners-listing-details-page .company-info h1,
  .patners-listing-details-page .company-info #social {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
  }

  section.about-company {
    padding: 70px 0;
  }

  section.about-company .about-details h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 3px;
    text-align: center;
  }

  section.about-company .about-details p {
    text-align: center;
  }

  .box--grid__item {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 23px;
  }

  section.contact-us {
    padding: 70px 0;
  }

  .smGlobalBtn {
    width: 40px;
    height: 40px;
    ;
    font-size: 22px;
    line-height: 40px;
    margin-left: 0;
    margin-right: 6px;
  }

  .patners-listing-details-page .company-info #social {
    text-align: left;
    margin-top: 7px;
  }

  .patners-listing-details-page .company-info {
    width: 100%;
    left: 10px;
  }
}
.box--grid__item {
    height: 100%;
}
.box--grid__item {
	padding: 10px 15px 5px 15px;
}
.box--grid__title {
	font-size: 18px;
}
.table {
	margin-bottom: 0;
}
.table td, .table th {
	padding: 2px .4rem;
	font-size: 14px;
}
.box--grid__item {
	height: unset;
}
</style>